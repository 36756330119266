.center {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-top:15px;
  padding-bottom: 15px;
}

.button {
  background: linear-gradient(145deg, #1f41bf, #2220b7);
  border: none;
  color: #ffffff;
  padding: 15px 25px;
  font-size: 1.2rem;
  font-family: "Assistant";
  direction: rtl;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 1s ease;
}

.button:hover {
  background: linear-gradient(145deg, #1f41bf, #2220b7);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.button:active {
  background: linear-gradient(145deg, #1f41bf, #2220b7);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(2px);
}

/* Responsive styles */
@media (max-width: 350px) {
  .button {
      padding: 10px 20px;
      font-size: 1rem;
      border-radius: 12px;
  }

}

@media (min-width: 350px) and (max-width: 450px) {
  .button {
      padding: 12px 22px;
      font-size: 1.1rem;
      border-radius: 13px;
  }

}

@media (min-width: 450px) and (max-width: 550px) {
  .button {
      padding: 13px 23px;
      font-size: 1.15rem;
      border-radius: 14px;
  }
}

@media (min-width: 550px) and (max-width: 750px) {
  .button {
      padding: 14px 24px;
      font-size: 1.18rem;
  }
}

@media (min-width: 750px) and (max-width: 950px) {
  .button {
      padding: 15px 25px;
      font-size: 1.2rem;
  }
}

@media (min-width: 950px) and (max-width: 1150px) {
  .button {
      padding: 16px 26px;
      font-size: 1.22rem;
      border-radius: 16px;
  }
}

@media (min-width: 1150px) {
  .button {
      padding: 17px 27px;
      font-size: 1.25rem;
      border-radius: 17px;
  }
}