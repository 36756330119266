.when {
    font-family: 'AssistantR', sans-serif;
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    width: 90%;
    max-width: 400px;
  }
  
  .email, .phone, .location {
    margin: 15px 0;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    word-break: break-word;
  }
  
  .email a, .phone a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
  }
  

  .email::before, .phone::before, .location::before {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
  }
  

  
  /* מתחת ל-350px */
  @media (max-width: 350px) {
    .when {
      padding: 15px;
      width:80%;
    }
  
    .email, .phone, .location {
      font-size: 14px;
    }
  
    .email::before, .phone::before, .location::before {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
  
  /* בין 350px ל-450px */
  @media (min-width: 350px) and (max-width: 450px) {
    .when {
      padding: 18px;
      width:75%;
    }
  
    .email, .phone, .location {
      font-size: 15px;
    }
  
    .email::before, .phone::before, .location::before {
      width: 18px;
      height: 18px;
      margin-right: 7px;
    }
  }
  @media (min-width: 450px) and (max-width: 550px) {
    .when {
      padding: 10px;
      width:65%;
    }

  }