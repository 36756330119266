.line-container-left {
    display: flex;
    align-items: center;
  }
  
  .line-container-right {
    display: flex;
    align-items: center;
    flex-direction: row-reverse; /* הפיכת הכיוון לצד שמאל */
  }
  
  .rectangle {
    width: 400px;
    position: relative;
    height: 70px;

    border-radius: 30px;
    background-color: lightgray;
    display: flex;
    font-size: 18px;
  direction: rtl;
    font-family: "AssistantR";
    justify-content: center;
    align-items: center;
background: linear-gradient(to right, #e5c94c, #ffd770, #e5c94c, #eeba0e);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
 
  }
  
  .circle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: lightgray;
    box-shadow: 0 0 3px 1px #222;
    display: flex;

    justify-content: center;
    align-items: center;
  position: absolute;
    font-size: 16px;
  }
  @media only screen and (max-width: 350px) {

    .line-container-left {
      display: flex;
      align-items: center;
    }
    
    .line-container-right {
      display: flex;
      align-items: center;
      flex-direction: row-reverse; /* הפיכת הכיוון לצד שמאל */
    }
    
    .rectangle {
      width: 90vw;
      position: relative;
      height: 50px;
  
      border-radius: 30px;
      background-color: lightgray;
      display: flex;
      font-size: 18px;
    direction: rtl;
      font-family: "AssistantR";
      justify-content: center;
      align-items: center;
  background: linear-gradient(to right, #e5c94c, #ffd770, #e5c94c, #eeba0e);
      box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
   
    }
    
    .circle {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: lightgray;
      box-shadow: 0 0 3px 1px #222;
      display: flex;
  
      justify-content: center;
      align-items: center;
    position: absolute;
      font-size: 14px;
    }

  }
  @media only screen and (min-width: 350px) and (max-width: 450px) {

    .line-container-left {
      display: flex;
      align-items: center;
    }
    
    .line-container-right {
      display: flex;
      align-items: center;
      flex-direction: row-reverse; /* הפיכת הכיוון לצד שמאל */
    }
    
    .rectangle {
      width: 85vw;
      position: relative;
      height: 55px;
  
      border-radius: 30px;
      background-color: lightgray;
      display: flex;
      font-size: 18px;
    direction: rtl;
      font-family: "AssistantR";
      justify-content: center;
      align-items: center;
  background: linear-gradient(to right, #e5c94c, #ffd770, #e5c94c, #eeba0e);
      box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
   
    }
    
    .circle {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background-color: lightgray;
      box-shadow: 0 0 3px 1px #222;
      display: flex;
  
      justify-content: center;
      align-items: center;
    position: absolute;
      font-size: 14px;
    }

  }
  @media only screen and (min-width: 450px) and (max-width: 550px) {

    .line-container-left {
      display: flex;
      align-items: center;
    }
    
    .line-container-right {
      display: flex;
      align-items: center;
      flex-direction: row-reverse; /* הפיכת הכיוון לצד שמאל */
    }
    
    .rectangle {
      width: 75vw;
      position: relative;
      height: 60px;
  
      border-radius: 30px;
      background-color: lightgray;
      display: flex;
      font-size: 18px;
    direction: rtl;
      font-family: "AssistantR";
      justify-content: center;
      align-items: center;
  background: linear-gradient(to right, #e5c94c, #ffd770, #e5c94c, #eeba0e);
      box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
   
    }
    
    .circle {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: lightgray;
      box-shadow: 0 0 3px 1px #222;
      display: flex;
  
      justify-content: center;
      align-items: center;
    position: absolute;
      font-size: 14px;
    }

  }