/* Base styles */
.card {
  background: none;
  border-radius: 8px;
  padding:32px 16px;
border:1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin:15px auto;
  width: 300px;
  transition: all 0.2s ease-in-out;
}

.card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
}

.icon {
  margin-bottom: 5px;
  width: 30%;
  justify-content: center;
}

.title {
  font-size: 1.4rem;
  margin: 5px auto;
  font-family: "Assistant";
  color: #121212;
}

.description {
  font-size: 1rem;
  color: #0b0b0b;
  line-height: 1.5;
  font-family: "AssistantR";
  direction: rtl;
}

/* Responsive styles */
@media (max-width: 350px) {
  .card {
      width: 80%;
      padding: 12px;
  }
  .icon {
      width: 25%;
  }
  .title {
      font-size: 1.2rem;
  }
  .description {
      font-size: 0.9rem;
  }
}

@media (min-width: 350px) and (max-width: 450px) {
  .card {
      width: 75%;
  }
  .icon {
      width: 28%;
  }
  .title {
      font-size: 1.3rem;
  }
  .description {
      font-size: 0.95rem;
  }
}

@media (min-width: 450px) and (max-width: 550px) {
  .card {
      width: 300px;
  }
}
