.title{
    font-size:3.5rem;
    font-family: "Assistant";
    text-align: center;
    margin:2% auto;
    color:rgb(255, 255, 255);
    text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
    direction:rtl
}
.description{
    font-size:1.4rem;
    color:rgb(255, 255, 255);
    font-family: "AssistantR";
    text-align: center;
    text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
    padding-top:2%;
    margin:0 auto;
    direction: rtl;
    width:50%;
}

.background{
height:auto;

width: 100vw;
background:  #011B3C;
}
.backgroundP{
    height:auto;
    width: 100vw;
    padding-top: 80px;
    background:  #011B3C;
}
.center{
    width:40%;
    justify-content: center;
    margin:0 auto 0;

}
.image{
    width: 100%;
    
    object-fit: contain;
}
@media (max-width: 350px) {
    .title {
        font-size: 2rem;
    }
    .description {
        font-size: 1.1rem;
        width: 95%;
    }
    .center {
        width: 100%;
    }
}
@media (min-width: 350px) and (max-width: 450px) {
    .title {
        font-size: 2.2rem;
    }
    .description {
        font-size: 1.1rem;
        width: 85%;
    }
    .center {
        width: 95%;
    }
}

@media (min-width: 450px) and (max-width: 550px) {
    .title {
        font-size: 2.5rem;
    }
    .description {
        font-size: 1.2rem;
        width: 80%;
    }
    .center {
        width: 85%;
    }
}

@media (min-width: 550px) and (max-width: 650px) {
    .title {
        font-size: 2.7rem;
    }
    .description {
        font-size: 1.25rem;
        width: 75%;
    }
    .center {
        width: 65%;
    }
}

@media (min-width: 650px) and (max-width: 750px) {
    .title {
        font-size: 2.9rem;
    }
    .description {
        font-size: 1.3rem;
        width: 70%;
    }
    .center {
        width: 65%;
    }
}

@media (min-width: 750px) and (max-width: 850px) {
    .title {
        font-size: 3.1rem;
    }
    .description {
        font-size: 1.35rem;
        width: 65%;
    }
    .center {
        width: 60%;
    }
}

@media (min-width: 850px) and (max-width: 950px) {
    .title {
        font-size: 3.2rem;
    }
    .description {
        font-size: 1.37rem;
        width: 60%;
    }
    .center {
        width: 55%;
    }
}

@media (min-width: 950px) and (max-width: 1050px) {
    .title {
        font-size: 3.3rem;
    }
    .description {
        font-size: 1.38rem;
        width: 55%;
    }
    .center {
        width: 50%;
    }
}

@media (min-width: 1050px) and (max-width: 1150px) {
    .title {
        font-size: 3.4rem;
    }
    .description {
        font-size: 1.39rem;
        width: 52%;
    }
    .center {
        width: 45%;
    }
}