/* Base styles */
.title {
    font-size: 3.5rem;
    font-family: "Assistant";
    text-align: center;
    margin: 2% auto;
    color: black;
    text-shadow: 1px 1px 7px rgba(0, 0, 0, 0.4);
    direction: rtl;
}

.center {
    width: 70%;
    justify-content: center;
    margin: 0 auto;
    padding-top: 1%;
    padding-bottom: 1%;
}

.image {
    width: 100%;
    object-fit: contain;
}

.description {
    font-size: 1.4rem;
    color: black;
    font-family: "AssistantR";
    text-align: center;
    text-shadow: 1px 1px 7px rgba(0, 0, 0, 0.4);
    padding-top: 2%;
    margin: 0 auto;
    direction: rtl;
    width: 50%;
}

.row {
    margin: 0 auto;
    padding-top: 2%;
    direction: rtl;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.background {
    background: linear-gradient(45deg, #F0E68C, #ebbc46, #F0E68C, #ebbc46);
    height: auto;
    width: 100vw;
}

/* Responsive styles */
@media (max-width: 350px) {
    .title {
        font-size: 1.8rem;
        
    }
    .center {
        width: 90%;
    }
    .description {
        font-size: 1.1rem;
        width: 90%;
    }
    .row {
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 350px) and (max-width: 450px) {
    .title {
        font-size: 2rem;
      
    }
    .center {
        width: 90%;
    }
    .description {
        font-size: 1.1rem;
        width: 85%;
    }
    .row {
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 450px) and (max-width: 550px) {
    .title {
        font-size: 2.5rem;
    }
    .center {
        width: 85%;
    }
    .description {
        font-size: 1.2rem;
        width: 80%;
    }
}

@media (min-width: 550px) and (max-width: 650px) {
    .title {
        font-size: 2.7rem;
    }
    .center {
        width: 85%;
    }
    .description {
        font-size: 1.25rem;
        width: 75%;
    }
}

@media (min-width: 650px) and (max-width: 750px) {
    .title {
        font-size: 2.9rem;
    }
    .description {
        font-size: 1.3rem;
        width: 70%;
    }
    .center {
        width: 80%;
    }
}

@media (min-width: 750px) and (max-width: 850px) {
    .title {
        font-size: 3.1rem;
    }
    .description {
        font-size: 1.35rem;
        width: 65%;
    }
    .center {
        width: 65%;
    }
}

@media (min-width: 850px) and (max-width: 950px) {
    .title {
        font-size: 3.2rem;
    }
    .description {
        font-size: 1.37rem;
        width: 60%;
    }
    .center {
        width: 60%;
    }
}

@media (min-width: 950px) and (max-width: 1050px) {
    .title {
        font-size: 3.3rem;
    }
    .description {
        font-size: 1.38rem;
        width: 55%;
    }
    .center {
        width: 55%;
    }
}

@media (min-width: 1050px) and (max-width: 1150px) {
    .title {
        font-size: 3.4rem;
    }
    .description {
        font-size: 1.39rem;
        width: 52%;
    }
    .center {
        width: 50%;
    }
}

@media (min-width: 1150px) and (max-width: 1250px) {
    .center {
        width: 40%;
    }
}
@media (min-width: 1250px) and (max-width: 1600px) {
    .center {
        width: 30%;
    }
}