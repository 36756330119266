/* Base styles */
.background {
    background: linear-gradient(45deg, #F0E68C, #ebbc46, #F0E68C, #ebbc46);
    height: auto;
    width: 100vw;
}
.description{
    font-size:1.4rem;
    color:rgb(0, 0, 0);
    font-family: "AssistantR";
    text-align: center;
    text-shadow: 1px 1px 7px rgba(255, 255, 255, 0.4);
    padding-bottom:2%;
    margin:0 auto;
    direction: rtl;
    width:50%;
}
.title {
    font-size: 3.5rem;
    font-family: "Assistant";
    text-align: center;
    padding-top: 2%;
    margin: 0 auto;
    direction: rtl;
}

.row {
    margin: 0 auto;
    padding-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    direction: rtl;
}

/* Responsive styles */
@media (max-width: 350px) {
    .title {
        font-size: 2rem;
        padding-top: 4%;
    }
    .row {
        flex-direction: column;
        align-items: center;
    }
    .description {
        font-size: 1.1rem;
        width: 95%;
    }
}

@media (min-width: 350px) and (max-width: 450px) {
    .title {
        font-size: 2.2rem;
        padding-top: 3.5%;
    }
    .row {
        flex-direction: column;
        align-items: center;
    }
    .description {
        font-size: 1.15rem;
        width: 95%;
    }
}

@media (min-width: 450px) and (max-width: 550px) {
    .title {
        font-size: 2.5rem;
        padding-top: 3%;
    }
    .row {
        justify-content: center;
    }
    .description {
        font-size: 1.2rem;
        width: 90%;
    }
}

@media (min-width: 550px) and (max-width: 650px) {
    .title {
        font-size: 2.7rem;
    }
    .row {
        justify-content: center;
    }
    .description {
        font-size: 1.25rem;
        width: 80%;
    }
}

@media (min-width: 650px) and (max-width: 750px) {
    .title {
        font-size: 2.9rem;
    }
    .description {
        font-size: 1.25rem;
        width: 80%;
    }
}

@media (min-width: 750px) and (max-width: 850px) {
    .title {
        font-size: 3.1rem;
    }
    .description {
        font-size: 1.3rem;
        width: 70%;
    }
}

@media (min-width: 850px) and (max-width: 950px) {
    .title {
        font-size: 3.2rem;
    }
    .description {
        font-size: 1.35rem;
        width: 65%;
    }
}

@media (min-width: 950px) and (max-width: 1050px) {
    .title {
        font-size: 3.3rem;
    }
}

@media (min-width: 1050px) and (max-width: 1150px) {
    .title {
        font-size: 3.4rem;
    }
}
